import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessaoService } from './sessao.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AcoesAutomaticaLeadsService {

  constructor(private http: HttpClient
    , private sessaoService: SessaoService) { }

  get(params) {
    return this.http.get(environment.environment.nomeServiceIntegra + 'AcoesAutomaticasLeads/getAll.json', { params: params });
  }

  getId(id) {
    return this.http.get(environment.environment.nomeServiceIntegra + `AcoesAutomaticasLeads/getId/${id}.json`);
  }

  salvar(params) {
    this.sessaoService.delete('AcoesAutomaticaLeadsService');
    return this.http.post(environment.environment.nomeServiceIntegra + 'AcoesAutomaticasLeads/postSave.json',  params );
  }

  listarTodosTiposConteudo() {
    return new Promise((resolve, reject) => {
      if (this.sessaoService.get('AcoesAutomaticaLeadsService')) {
        resolve(this.sessaoService.get('AcoesAutomaticaLeadsService'));
      } else {
        this.http.get(environment.environment.nomeServiceIntegra + 'AcoesAutomaticasLeads/getAll.json')
          .toPromise().then((res: any) => {
            this.sessaoService.set('AcoesAutomaticaLeadsService', res);
            resolve(res);
          }, err => {
            reject(err);
          });
      }
    });
  }
  delete(id) {
    return this.http.delete(`${environment.environment.nomeServiceIntegra}AcoesAutomaticasLeads/delete/${id}.json`, {});
  }

}
