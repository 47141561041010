import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExportarLeadsService } from './exportar-leads.service';

@Component({
  selector: 'app-exportar-leads',
  templateUrl: './exportar-leads.component.html',
  styleUrls: ['./exportar-leads.component.css']
})
export class ExportarLeadsComponent implements OnInit {

  @Output() exportar = new EventEmitter<any>();
  @Input()  filtrarStatus: boolean = false;
    //colunasExport = [];
  selectedFields = {colunas: [],status: ''};
  allSelected = true;
  listStatus = [{ nome: 'Aguardando', value: 'Aguardando' }, { nome: 'Processando', value: 'Processando' }, { nome: 'Sucesso', value: 'Sucesso' }, { nome: 'Falha', value: 'Falha' }, { nome: 'Cancelado', value: 'Cancelado' }, { nome: 'Status da Importação', value: '' }];
status: any;
  constructor(private exportarService: ExportarLeadsService) { }

  ngOnInit(): void {
    this.selectedFields.colunas = this.exportarService.getColunasExport();
    this.exportar.emit(this.selectedFields);
  }

  toggleAllSelected(): void {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
        this.selectedFields.colunas.forEach(f => f.checked = true);
    } else {
      this.selectedFields.colunas.forEach(f => f.checked = false);
    }
    this.exportar.emit(this.selectedFields);
  }


}
