import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UltimoAcessoPipe } from './ultimo-acesso.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [UltimoAcessoPipe],
  providers: [DatePipe],
  exports: [UltimoAcessoPipe]
})
export class UltimoAcessoModule { }
