<div *ngIf="selectedFields?.colunas">
    <div class="form-check mb-3">
        <div class="row">
            <div class="col-3">
                <button type="button" class="btn btn-primary" (click)="toggleAllSelected()">Marcar/Desmarcar
                    Todos</button>
            </div>
            <div class="col-6" *ngIf="filtrarStatus">

                <div class="form-group">
                    <ng-select [items]="listStatus" bindLabel="nome" bindValue="value" placeholder="Selecionar status..."
                        [(ngModel)]="selectedFields.status" name="status">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row mb-3" style="padding-left: 30px;" *ngIf="selectedFields?.colunas">


            <div *ngFor="let coluna of selectedFields.colunas " class="form-check mb-3 col-4">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [(ngModel)]="coluna.checked" [value]="coluna.value"
                        (change)="exportar.emit(selectedFields);" />
                    {{ coluna.name }}</label>
            </div>
        </div>
    </div>
