import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WiseMindService {
  private scriptSrc = 'https://assets.wisemind.app/5436a7bf-c3b9-4032-81dc-cc3873d3cca0/a5a97f91-ec3d-4df0-8e82-f6bf33f44de7/widget/module.js?api_key=17c184c6-ca20-468a-bd52-749af0c3701e';
  private scriptElement?: HTMLScriptElement;

  loadScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scriptSrc = environment.environment.urlWiseMind ? environment.environment.urlWiseMind : this.scriptSrc;
      if (!this.scriptElement && this.scriptSrc && environment.environment.useWiseMind) {
        this.scriptElement = document.createElement('script');
        this.scriptElement.src = this.scriptSrc;
        this.scriptElement.async = true;
        this.scriptElement.onload = () => resolve();
        this.scriptElement.onerror = (err) => reject(err);
        document.body.appendChild(this.scriptElement);
      }
    });
  }

  removeScript(): void {
    // Remove o script
    if (this.scriptElement) {
      document.body.removeChild(this.scriptElement);
      this.scriptElement = undefined;
    }

    // Remover elementos adicionais relacionados ao chatbot (como divs ou iframes)
    const chatbotElements = document.querySelectorAll('iframe, .ws__chat, .ws__ai-avatar');
    chatbotElements.forEach((el) => el.remove());

    console.log('Chatbot removido com sucesso!');
  }
}
