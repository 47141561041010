<div class="animated fadeIn">

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-search"></i>
            Pesquisa de <strong>Ações Automáticas</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Nome da Ação:</label>
                  <input class="form-control" type="text" [(ngModel)]="filter.nome" name="nome">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                    <label>Nome do Responsável:</label>
                    <autocomplete-usuario [(ngModel)]="filter.responsavel" name="responsavel"></autocomplete-usuario>
                </div>
            </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Ativo:</label>
                  <ng-select [items]="listStatus" bindLabel="name" bindValue="value" placeholder="Selecione um status"
                    name="ativo" [(ngModel)]="filter.ativo">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="form-group pull-left">
                <br>
                <button type="button" class="btn btn-primary" (click)="pesquisar()">
                  <i class="fa fa-search"></i>
                  Pesquisar
                </button>
                &nbsp;&nbsp;
                <a class="btn btn-success" href="#" [routerLink]="['/acoes-automaticas-lead/form/0']">
                  <i class="fa fa-plus"></i> Cadastrar Ação Automática</a>
                &nbsp;&nbsp;
                <button type="button" class="btn btn-secondary" (click)="limparFiltros()">
                  <i class="fa fa-ban"></i>
                  Limpar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-align-justify"></i>
            Resultados ({{configuracoes && configuracoes.TotalItems ? configuracoes.TotalItems : 0}})
          </div>
          <div class="card-body" *ngIf="configuracoes && configuracoes.Items">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center">Código</th>
                  <th>Nome da Ação</th>
                  <th>Responsável</th>
                  <th>Segmentação de Leads</th>
                  <th>Executado</th>
                  <th class="text-center">Ativo</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ta of configuracoes.Items; let i = index" style="cursor: pointer;">
                  <td class="text-center">{{ta.id}}</td>
                  <td >{{ta.nome_acao}}</td>
                  <td>{{ta.responsavel.nome}}</td>
                  <td>
                    <div class="d-flex flex-column">
                      <div (click)="atualizarContador(ta?.id,ta?.conteudo_condicao_id)" class="d-inline-block" style="cursor: pointer;">
                        <span class="badge bg-success text-white" ngbTooltip="Clique aqui se deseja atualizar o contador, considere atualizações de 5 minutos em 5 minutos">
                          {{ totalLeads[ta?.id] }}
                        </span>
                        <span> {{ ta.conteudo_condicao.nome }}</span>
                      </div>
                    </div>
                  </td>
                  <td>{{ta.ultima_execucao | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                  <td class="text-center"><span
                      [ngClass]="ta.ativo == 1 ? 'badge badge-success' : 'badge badge-danger'">{{ta.ativo
                      == 1 ? 'Sim' : 'Não'}}</span>
                  </td>

                  <td class="td-actions text-left">

                    <div class="btn-group" dropdown placement="bottom right">
                        <button dropdownToggle type="button" class="btn btn-sm dropdown-toggle">
                            Ações <span class="caret"></span>
                        </button>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="menuitem">
                          <button type="button" class="dropdown-item" (click)="editarRegistro(ta.id)" tooltip="Editar">
                            <i class="fa fa-pencil fa-lg mt-1 "></i> Editar
                          </button>
                        </li>
                        <li role="menuitem">
                          <button type="button" class="dropdown-item" (click)="deletar(ta)" tooltip="Excluir">
                            <i class="fa fa-times fa-lg mt-1 "></i> Excluir
                          </button>
                        </li>
                        <li class="divider dropdown-divider"></li>
                        <li role="menuitem">
                            <button type="button" class="dropdown-item"
                                (click)="editarLista(ta.conteudo_condicao_id)" tooltip="Editar">
                                <i class="fa fa-pencil fa-lg mt-1 "></i> Editar/Visualizar Lista
                            </button>
                        </li>
                      </ul>
                    </div>

                  </td>
                </tr>
                <tr *ngIf="configuracoes && configuracoes.Items == 0">
                  <td colspan="8">Não existe tipo de atividades com estes parâmetros</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template confirm>
    <confirm-modal-component></confirm-modal-component>
 </ng-template>
