import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CanalAtendimentoService } from '../../services/canal-atendimento.service';
import { CategoriaService } from '../../services/categoria.service';
import { CampanhaTipoService } from '../../services/campanha-tipo.service';

@Component({
  selector: 'app-tipo-campanhas',
  templateUrl: './tipo-campanhas.component.html',
  styleUrls: ['./tipo-campanhas.component.scss']
})
export class TipoCampanhasComponent implements OnInit {

  tipoCampanhas: any;
  filter: any;
  canaisAtendimento: any = [];
  categoriasInstrumento: any = [];
  listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }];

  constructor(private campanhaTipoService: CampanhaTipoService
    , private canalAtendimentoService: CanalAtendimentoService
    , private categoriaService: CategoriaService
    , private router: Router) { }

  async ngOnInit() {
    this.initFiltros();

    this.canalAtendimentoService.listarTodosCanaisAtendimento().then(response => {
      this.canaisAtendimento = response;
    });

    this.categoriaService.listarTodosInstrumentos().then(response => {
      this.categoriasInstrumento = response;
    });

    this.pesquisar();
  }

  initFiltros() {
    this.filter = {
      nome: '',
      ativo: ''
    };
  }


  async pesquisar() {
    this.filter.CodCid = this.filter.CodCid ? this.filter.CodCid : '';
    this.tipoCampanhas = await this.campanhaTipoService.get(this.filter).toPromise();
  }

  editarRegistro(id: number) {
    this.router.navigate(['/tipo-campanhas/form', id]);
  }

  limparFiltros() {
    this.initFiltros();
  }

  getNomeCanalAtendimento(CodMeioAtendimento){
    if(this.canaisAtendimento){
        let canais = this.canaisAtendimento.filter((item: any) => item.CodMeioAtendimento == CodMeioAtendimento);
        return canais.length > 0 ? canais[0].DescMeioAtendimento : '';
    } else {
        return '';
    }
  }

  getNomeInstrumento(CodCategoria){
    if(this.categoriasInstrumento){
        let instrumentos = this.categoriasInstrumento.filter((item: any) => item.CodCategoria == CodCategoria);
        return instrumentos.length > 0 ? instrumentos[0].DescCategoria : '';
    } else {
        return '';
    }
  }

}
