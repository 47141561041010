import { Component, OnInit } from '@angular/core';
import { AcoesAutomaticaLeadsService } from '../../services/acoes-automatica-leads.service';
import { Router } from '@angular/router';
import { ConfirmService } from '../../shared/confirm-modal/confirm-modal-and-service';
import { ToastrService } from 'ngx-toastr';
import { SessaoService } from '../../services/sessao.service';
import { SegmentacaoLeadsService } from '../../services/segmentacao-leads.service';

@Component({
  selector: 'app-acoes-automatica-leads',
  templateUrl: './acoes-automatica-leads.component.html',
  styleUrls: ['./acoes-automatica-leads.component.css']
})
export class AcoesAutomaticaLeadsComponent implements OnInit {

configuracoes: any;
filter: any;
listStatus = [ { name: 'Todos', value: '' },{ name: 'Ativo', value: 1 }, { name: 'Inativo', value: 0 }];
searchFailedUser = false;
totalLeads: any = [];

constructor(
    private acoesAutomaticaLeadsService: AcoesAutomaticaLeadsService
, private sessaoService: SessaoService
    , private router: Router
     , private confirmService: ConfirmService
       , private toastr: ToastrService
    , private segmentacaoLeadsService: SegmentacaoLeadsService
    ) { }

  async ngOnInit() {
    this.initFiltros();
    await this.pesquisar();
  }
  initFiltros() {
    this.filter = {
      nome: '',
      ativo: '',
      responsavel_id: this.sessaoService.getUsuarioId(),
      responsavel:{usuario: {id: this.sessaoService.getUsuarioId(), nome: this.sessaoService.getUsuarioNome()}},
    };

  }


  async pesquisar() {
    this.filter.responsavel_id = this.filter?.responsavel?.usuario?.id?? "";
    this.configuracoes = await this.acoesAutomaticaLeadsService.get(this.filter).toPromise();

    this.configuracoes.Items.forEach(element => {
        this.atualizarContador(element.id, element.conteudo_condicao_id);
    });
  }


  limparFiltros() {
    this.initFiltros();
  }

  editarRegistro(id: number) {
    this.router.navigate(['/acoes-automaticas-lead/form', id]);
  }


  deletar(acao: any ) {
    this.confirmService.confirm({
      title: 'Exclusão', message: 'Você tem certeza que deseja excluir a ação automática ' + acao.nome_acao +' ?'
    }).then(
      () => {
        this.acoesAutomaticaLeadsService.delete(acao.id).subscribe(response => {
          this.pesquisar();
          this.toastr.success('Ação automática removida com sucesso!');

        });
      },
      () => { });
  }

    editarLista(conteudo_condicao_id) {
        this.router.navigate(['/segmentacao-leads/form', conteudo_condicao_id]);
    }

  atualizarContador(id, conteudo_condicao_id) {
    this.segmentacaoLeadsService.postVisualizacaoLeadsPorQuery({ conteudo_condicao_id: conteudo_condicao_id, ignoreLeads: true }).toPromise().then(response => {
        this.totalLeads[id] = response.TotalItems;
    });
}

}
