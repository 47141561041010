import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import { SegmentacaoLeadsService } from "../../services/segmentacao-leads.service";
import { ConfirmService } from '../../shared/confirm-modal/confirm-modal-and-service';
import { ToastrService } from "ngx-toastr";
import { ConteudoService } from "../../services/conteudo.service";
import { Router } from "@angular/router";
import { SessaoService } from "../../services/sessao.service";
import { UsuarioService } from "../../services/usuario.service";
import { environment } from "../../../environments/environment";
import { WorkflowService } from "../../services/workflow.service";

@Component({
    selector: "app-segmentacao-leads-acoes",
    templateUrl: "./segmentacao-leads-acoes.component.html",
    styleUrls: ["./segmentacao-leads-acoes.component.css"],
})
export class SegmentacaoLeadsAcoesComponent implements OnInit {
    @ViewChild('modalExecutarFluxo') public modalExecutarFluxo: ModalDirective;
    @Output() atualizarSegmentos = new EventEmitter<any>();
    @Input() s: any;
    @Input() isEdit: boolean = false;
    //segmentacoesLeads: any;
    segmento_id: number;
    executarNovoFluxo: boolean = false;
    workflow: any = { nome: null, descricao: null };
    workflows: any;
    totalLeads: any = [];
    qtdeItem:number;
    // filter: any;
    // maxSize: number;
    // totalItems: number;
    // listItemsPerPage = [5, 10, 15];
   // listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }];
   selectedFields = { colunas: [] };
    modalRef?: BsModalRef;
    config = {
        backdrop: true,
        ignoreBackdropClick: true,
    };
    fluxo: number;
    constructor(
        private modalService: BsModalService,
        private confirmService: ConfirmService,
        private toastr: ToastrService,
        private conteudoService: ConteudoService,
        private sessaoService: SessaoService,
        private router: Router,
        private usuarioService: UsuarioService,
        private workflowService: WorkflowService,
        private segmentacaoLeadsService: SegmentacaoLeadsService
    ) {}

    ngOnInit(): void {
        this.permissaoExecutarNovoFluxo();
        this.workflowService.getWorkflows().then((w: any) => {
            this.workflows = w;
        });
    }
    exportarLeads(template: any): void {
        this.config["class"] = "modal-xl";
        this.config["scrollable"] = true;
        this.config["segmentacaoId"] = this.s.id;

        this.modalRef = this.modalService.show(template, this.config);
    }
    reprocessarLeads() {
        this.confirmService
            .confirm({
                title: "Reprocessar Leads",
                message:
                    "Utilize essa ação para reprocessar leads que estão com falha. Você tem certeza que deseja reprocessar os Leads da segmentação de nome <b>" +
                    this.s.nome +
                    "</b> ?",
            })
            .then(
                () => {
                    this.atualizarSegmentos.emit({salvar: true});

                    this.segmentacaoLeadsService
                        .reprocessarLeads(this.s.id)
                        .subscribe((response) => {
                            this.toastr.success(response["message"]);
                        });
                },
                () => {}
            );
    }

    openModalExecutarFluxo() {
        this.atualizarSegmentos.emit({salvar: true});
        this.segmento_id = null;
        if (this.s) {
            this.segmento_id = this.s.id;
        }
        this.qtdeItem = this.totalLeads[this.s.id];
        this.modalExecutarFluxo.show();
    }

    excluirLeads() {
        this.atualizarSegmentos.emit({salvar: true});
        this.confirmService.confirm({
            title: 'Exclusão', message: 'Utilize essa ação para excluir os leads com falha do banco de dados. Você tem certeza que deseja excluir os Leads da segmentação de nome <b>' + this.s.nome + '</b> ?'
        }).then(
            () => {
                this.segmentacaoLeadsService.excluirLeads(this.s.id).subscribe(response => {
                    this.toastr.success(response['message']);
                });
            },
            () => { });
    }
    async excluirAtendimentosSasLeadsFalha() {
        this.atualizarSegmentos.emit({salvar: true});
        const leads: any = await this.segmentacaoLeadsService.postVisualizacaoLeadsPorQuery({ query: JSON.parse(this.s.filtros_json), ignoreLeads: false }).toPromise().then(response => {
            return response.Leads;
        });
        const ids = [];
        leads.forEach(lead => {
            ids.push(lead.id);
        });
        if (!ids.length) {
            this.toastr.warning('Este segmento não possui leads!', 'Atenção');
            return false;
        }
        this.confirmService.confirm({
            title: 'Excluir Atendimento SAS', message: 'Essa ação exclui o protocolo de atendimento gerado no SAS, confirmar a exclusão dos protocolos?'
        }).then(
            () => {
                this.conteudoService.excluirAtendimentoSasLeadsFalha(ids).subscribe(response => {
                    this.toastr.success('Aguarde o processamento do workflow para exclusão do atendimento no SAS!');
                }, e => {
                    console.log(e);
                });
            },
            () => { });
    }

    reenviarLeadsTravados() {
        this.atualizarSegmentos.emit({salvar: true});
        this.confirmService.confirm({
            title: 'Reenviar Leads Travados', message: 'Utilize essa ação apenas se você perceber que existe leads no status de aguardando ou processando por muito tempo. Confirma o reenvio dos Leads para a fila de processamento, segmentação de nome <b>' + this.s.nome + '</b> ?'
        }).then(
            () => {
                this.segmentacaoLeadsService.reenviarLeadsTravadosParaFila(this.s.id).subscribe(response => {
                    this.toastr.success(response['message']);
                });
            },
            () => { });
    }

    editarRegistro() {
        this.router.navigate(['/segmentacao-leads/form', this.s.id]);
    }
    exportCSV(modalRef: any): void {
        this.atualizarSegmentos.emit({salvar: true});
        this.segmentacaoLeadsService.exportarLeads(this.config['segmentacaoId'], this.selectedFields.colunas.filter(f => f.checked == true).map(m => m.value));
        modalRef.hide();
    }

    // async getSegmentos(): Promise<void> {
    //     this.filter.responsavel_id = this.filter.usuario && this.filter.usuario.id ? this.filter.usuario.id : '';
    //     this.sessaoService.set('FiltrosSegmentacaoLeads', this.filter);
    //     let response = await this.segmentacaoLeadsService.get(this.filter).toPromise();
    //     this.segmentacoesLeads = response.ConteudoCondicoes;
    //     this.totalItems = response.TotalItems;

    //     this.segmentacoesLeads.forEach(element => {
    //         this.atualizarContador(element.filtros_json, element.id);
    //     });
    //     let maxSize = Math.ceil(this.totalItems / this.filter.limit);
    //     this.maxSize = maxSize <= 15 ? maxSize : 15;
    // }

    // atualizarContador(query, i) {
    //     this.segmentacaoLeadsService.postVisualizacaoLeadsPorQuery({ query: JSON.parse(query), ignoreLeads: true }).toPromise().then(response => {
    //         this.totalLeads[i] = response.TotalItems;
    //     });
    // }

    deletar() {
        this.confirmService.confirm({
            title: 'Exclusão', message: 'Você tem certeza que deseja excluir a segmentação de nome <b>' + this.s.nome + '</b> ?'
        }).then(
            () => {

                this.segmentacaoLeadsService.delete(this.s.id).subscribe(response => {
                    this.atualizarSegmentos.emit({novo: true});
                    this.toastr.success('Segmentação de leads excluído com sucesso!');
                });
            },
            () => { });
    }

    setColunasExport($event){
        this.selectedFields = $event;
    }

    public mostraWorkflow(obj) {
        this.workflow.nome = obj.nome;
        this.workflow.descricao = obj.descricao;
    }
    salvarExecutarNovoFluxo() {

        if (!this.fluxo) {
            this.toastr.warning('Selecione um Fluxo!', 'Atenção');
            return false;
        }
        let params = { segmento_id: this.segmento_id, workflow_id: this.fluxo };
        this.segmentacaoLeadsService.executarNovoFluxo(params).subscribe(response => {
            this.toastr.success(response['message']);
        });
        this.modalExecutarFluxo.hide();
    }
    async permissaoExecutarNovoFluxo() {
        const dados = {
            usuario_id: this.sessaoService.getUsuarioId(),
            projeto: environment.environment.projeto,
            metodo: 'postExecutarNovoFluxo',
            modulo: 'ConteudoCondicoes'
        };
        this.executarNovoFluxo = await this.usuarioService.getIsAuthorized(dados).toPromise();

    }

}
