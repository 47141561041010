import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportarLeadsComponent } from './exportar-leads.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    ExportarLeadsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  exports:[ExportarLeadsComponent]
})
export class ExportarLeadsModule { }
