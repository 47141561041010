import { Component, OnInit } from '@angular/core';
import { SegmentacaoLeadsService } from '../../services/segmentacao-leads.service';
import { SessaoService } from '../../services/sessao.service';

@Component({
    selector: 'app-segmentacao-leads',
    templateUrl: './segmentacao-leads.component.html',
    styleUrls: ['./segmentacao-leads.component.css']
})
export class SegmentacaoLeadsComponent implements OnInit {
    //@ViewChild('modalExecutarFluxo') public modalExecutarFluxo: ModalDirective;
    segmentacoesLeads: any;
    filter: any;
    totalItems: number;
    totalLeads: any = [];
    currentPage: number = 1;
    maxSize: number;
     qtdeItem:number;
     listItemsPerPage = [5, 10, 15];
     s: any;
    constructor(
        private segmentacaoLeadsService: SegmentacaoLeadsService
        , private sessaoService: SessaoService) { }

    async ngOnInit() {
      //  this.permissaoExecutarNovoFluxo();
        this.initFiltros();
        this.getSegmentos();

    }

    initFiltros() {
        let FiltrosSegmentacaoLeads: any = this.sessaoService.get('FiltrosSegmentacaoLeads');
        if (FiltrosSegmentacaoLeads) {
            this.filter = FiltrosSegmentacaoLeads;
        } else {

            this.filter = {
                nome: '',
                usuario: { id: this.sessaoService.getUsuarioId(), nome: this.sessaoService.getUsuarioNome() },
                responsavel_id: '',
                ativo: '',
                page: 1,
                limit: 5
            };
        }
    }

    pageChanged(event: any): void {
        this.filter.page = event.page;
        this.filter.limit = event.itemsPerPage;
        this.getSegmentos();
    }
    novo($event) {
        if($event?.salvar != true){
            this.s = null;
        }
      }

    async getSegmentos(): Promise<void> {
        this.filter.responsavel_id = this.filter.usuario && this.filter.usuario.id ? this.filter.usuario.id : '';
        this.sessaoService.set('FiltrosSegmentacaoLeads', this.filter);
        let response = await this.segmentacaoLeadsService.get(this.filter).toPromise();
        this.segmentacoesLeads = response.ConteudoCondicoes;
        this.totalItems = response.TotalItems;
        //this.s = response;

        this.segmentacoesLeads.forEach(element => {
            this.atualizarContador(element.filtros_json, element.id);
        });
        let maxSize = Math.ceil(this.totalItems / this.filter.limit);
        this.maxSize = maxSize <= 15 ? maxSize : 15;
    }

    pesquisar() {
        this.filter.page = 1;
        this.currentPage = 1;

        this.getSegmentos();
    }

    limparFiltros() {
        this.sessaoService.delete('FiltrosSegmentacaoLeads');
        this.initFiltros();
        this.getSegmentos();
    }


    atualizarContador(query, i) {
        this.segmentacaoLeadsService.postVisualizacaoLeadsPorQuery({ query: JSON.parse(query), ignoreLeads: true }).toPromise().then(response => {
            this.totalLeads[i] = response.TotalItems;
        });
    }



}
