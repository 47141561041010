import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'ultimoAcesso'
})
export class UltimoAcessoPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(ultimoAcesso: string | Date, minutosOnline: number = 5): string {
    if (!ultimoAcesso) {
      return `<span class="badge badge-warning">Nunca acessou</span>`;
    }

    const ultimoAcessoDate = new Date(ultimoAcesso);
    const agora = new Date();
    const diffMinutos = (agora.getTime() - ultimoAcessoDate.getTime()) / (1000 * 60);
    const dataFormatada = this.datePipe.transform(ultimoAcessoDate, 'dd/MM/yyyy H:mm');

    if (diffMinutos <= minutosOnline) {
      return `<span class="badge badge-success">Último acesso em ${dataFormatada}</span>`;
    } else {
      return `<span class="badge badge-primary">Último acesso em ${dataFormatada}</span>`;
    }
  }
}
